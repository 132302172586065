@charset "utf-8";


/************************************************

	cart

*************************************************/
	
[color="#000000"] {
	color: #464646;
}

.responsiveDesignHeader {
	background-color: #3e3e3e;
	
	@media screen and (max-width:768px) {
		display: none;
	}
	
	td {
		padding: 1em 0;
		letter-spacing: 0.1em;
	}
}

.responsiveDesignHeadImg {
	@media screen and (max-width:768px) {
		padding: 10px 10% 0;
	}
	
	+ div {
		.globalAlign {
			.responsiveDesignHide {
				@media screen and (max-width:768px) {
					display: block !important;
				}
			}
			
			.responsiveDesignShow {
				@media screen and (max-width:768px) {
					display: none !important;
				}
			}
		}
	}
}

.responsiveDesignBasket,
.responsiveDesignSectionInput,
.responsiveDesignSectionUser,
.responsiveDesignDelivery,
.responsiveDesignTotalPrice,
.responsiveDesignSectionReturnGoods,
.settlementCompleteMessage {
	border-radius: 0;
	box-shadow: none;
	background-color: #cacaca;
}
	
.responsiveDesignBasket {
	.responsiveDesignBasketHeader {
		background-color: #f1f1f1;
		
		> td {
			@media screen and (max-width:768px) {
				background-color: #f1f1f1;
				
				* {
					color: #464646;
				}
			}
			
			strong * {
				color: #464646;
			}
		}
	}
}
	
.responsiveDesignBasket,
.responsiveDesignTotalPrice {
	td {
		padding: 1em;
		
		@media screen and (max-width:768px) {
			padding: 1em 0.5em;
			background: #fff;
		}
	}
}

.responsiveDesignTotalPrice {
	@media screen and (max-width:768px) {
		border-color: #cacaca;
	}
}

.responsiveDesignSectionInput {
	.responsiveDesignSectionInputHeader {
		background-color: #cacaca;
		text-align: center;
		
		> td {
			padding: 1em!important;
			
			* {
				color: #464646;
			}
			
			b {
				display: block;
			}
		}
	}

	.responsiveDesignSectionUserHeaderSide {
		
	}
	
	.amazon_login_hide_item {
		
	}
	
	> tbody {
		> tr {
			> td {
				&.responsiveDesignHide {
					background-color: #f1f1f1;
				}
			}
		}
	}
}

.basketTotal .basketTotalPrice,
.basketConfirmTotal .basketTotalPrice {
	color: #cc0000;
}

.responsiveDesignSectionUser {
	.responsiveDesignSectionUserHeaderTop {
		background-color: #cacaca;
		text-align: center;
		
		@media screen and (max-width:768px) {
			
			* {
				color: #464646;
			}
		}
		
		> td {
			padding: 1em!important;
			
			font {
				color: #464646;
			}
			
		}
		
		+ tr {
			b.responsiveDesignShow {
				padding: 0!important;
				
				@media screen and (max-width:768px) {
					border-bottom: 0;
				}
			}
		}
	}
	
	.responsiveDesignSectionUserHeaderSide {
		background-color: #f1f1f1;
	}
	
	[bgcolor="#cccccc"] {
		background-color: #f1f1f1;
	}
}

.responsiveDesignDelivery {
	.responsiveDesignBasketHeader {
		background-color: #f1f1f1;
				
		@media screen and (max-width:768px) {
			
		}
		
		
	}
	
	td {
		padding: 1em!important;

		@media screen and (max-width:768px) {

		}
	}
}

.other-payment {
	.payment-title-pc {
		background-color: #f1f1f1;
	}
}
	
@media screen and (max-width:768px) {
	.responsiveDesignSectionUserHeaderSide {
		//background-color: #f1f1f1;
	}
}

input[name="next_step_button"],
.nextBtnWrap input[type="button"] {
	border-radius: 0;
	background: #464646;
	padding: 1em!important;
}

input[type="text"],
input[type="tel"],
textarea,
table#loginTable .point input[type="text"],
input[type="email"],
table#loginTable .point input[type="password"],
table#loginTable .point input[type="email"] {
	&:focus {
		background-color: #f8f8f8;
	}
}

input[type="button"],
input[type="submit"],
.responsiveDesignModifyButton,
.responsiveDesignOrderButton {
	border-radius: 0!important;
}


/*複数配送*/
.orderSortTable {
	.responsiveDesignBasketHeader {
		background-color: #f1f1f1;
		
		strong * {
			color: #464646;
		}
	}
}

/*決済・配送方法選択*/
.pluralOrder-wrap {
	border-radius: 0!important;
	box-shadow: none!important;
	border: none!important;
}

/*注文備考*/
.responsiveDesignSectionInput {
	td {
		&:has(>textarea[name="message1"]) {
			padding-top: 3em!important;
			position: relative;
	
			&::before {
				content: 'その他のお問い合わせは下記にご記入ください。';
				position: absolute;
				top: 1em;
			}
		}
	}
}

/*注文内容確認*/
.responsiveDesignTotalPrice {
	hr {
		opacity: 0.3;
	}
	
	[color="blue"] {
		color: #464646;
	}
}

.responsiveDesignSectionReturnGoods {
	td {
		padding: 1em;
	}
}

.responsiveDesignBasketFooter {
	.totalPriceItems {
		.totalPriceItems-main {
			@media screen and (max-width:768px) {
				.totalPriceItems-label {
					width: 7em;
				}

				.totalPriceItems-price {
					text-align: left;
				}
			}
		}
	}
}


/*注文完了*/
.settlementCompleteMessage {
	[color="#0000FF"] {
		color: #0054A6!important;
	}
	
	[color="red"] {
		color: #cc0000!important;
	}
	
	+ .globalAlign {
		input {
			border-radius: 0!important;
		}
	}
}